import React, { useState } from "react";
import firebase from "gatsby-plugin-firebase";
import PageWrapper from "../components/PageWrapper";

const Contact = () => {
    const emptyData = {
        Organization: "",
        Address: "",
        Street: "",
        City: "",
        Country: "",
        OrgIsd: "",
        OrgPhone: "",
        YourName: "",
        Designation: "",
        Email: "",
        YourIsd: "",
        YourPhone: "",
        Subject: "",
        Message: "",
    }
    const [data, setData] = useState(emptyData)
    const handleChange = e =>
        setData({ ...data, [e.target.name]: e.target.value })

    const handleSubmit = (e) => {
        console.log('handleSubmit')
        e.preventDefault();
        let myForm = document.getElementById("customer-contact");
        console.log(data);
        data["created"] = firebase.firestore.Timestamp.now();
        firebase
            .firestore()
            .collection('master-contacts')
            .add({ email: data["Email"], type: "customer-contact", created: data["created"] })
            .then(() => {
                console.log("Form successfully saved in firestore")
            })
            .catch((error) => {
                console.error("Error adding document to master-contacts: ", error);
            });
        firebase
            .firestore()
            .collection('customer-contacts')
            .add(data)
            .then(() => {
                console.log("Form successfully saved in firestore")
            })
            .catch((error) => {
                console.error("Error adding document to customer-contacts: ", error);
            });
        setData(emptyData);
    };
    return (
        <>
            <PageWrapper>
                <div className="bg-default-2 pt-16 pb-12 pt-lg-22 pb-lg-27">
                    <div className="container">
                        <div className="row justify-content-center mt-14">
                            <h2 className="font-size-9 text-center mb-11">Customer Contact</h2>
                            <div className="mb-8">
                                <p className="text-default-color font-size-4 px-5">
                                    Drive your innovation and ideas to successful business outcomes faster – Come collaborate with us! We are your trusted technology partner. Brainstorm with leading CTOs on best technology directions and options, consult on high level design, execution, and implementation. Utilize our global network of domain experts, proven product and system design experts and engineers. We are Your extended R&D and CTO team – VOCALGLOCAL.org
                                </p>
                                <p className="text-default-color font-size-4 px-5 ">Reach out to us and share your idea. Get our expert feedback and engage with us.</p>
                            </div>
                            <div className="col-xxl-6 col-xl-7 col-lg-8">

                                <div className="bg-white px-9 pt-9 pb-7 shadow-8 rounded-4">
                                    <form
                                        id="customer-contact"
                                        name="customer-contact"
                                        method="post"
                                        onSubmit={handleSubmit}
                                    >
                                        <input type="hidden" name="bot-field" />
                                        <input type="hidden" name="form-name" value="customer-contact" />
                                        <div className="row">
                                            <div className="col-6 mb-7">
                                                <label
                                                    htmlFor="Organization"
                                                    className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                                                >
                                                    Organization
                                                    <input
                                                        type="text"
                                                        className="form-control mt-5"
                                                        placeholder="Organization Name"
                                                        id="Organization"
                                                        name="Organization"
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </label>
                                            </div>


                                            <div className="col-lg-6 mb-7">
                                                <label
                                                    htmlFor="Address"
                                                    className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                                                >
                                                    Address
                                                    <input
                                                        type="text"
                                                        className="form-control mt-5"
                                                        placeholder="Address"
                                                        id="Address"
                                                        name="Address"
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </label>
                                            </div>
                                            <div className="col-lg-6 mb-7">
                                                <label
                                                    htmlFor="Street"
                                                    className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                                                >
                                                    Street
                                                    <input
                                                        type="text"
                                                        className="form-control mt-5"
                                                        placeholder="Street"
                                                        id="Street"
                                                        name="Street"
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </label>
                                            </div>

                                            <div className="col-lg-6 mb-7">
                                                <label
                                                    htmlFor="City"
                                                    className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                                                >
                                                    City
                                                    <input
                                                        type="text"
                                                        className="form-control mt-5"
                                                        placeholder="City"
                                                        id="City"
                                                        name="City"
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </label>
                                            </div>
                                            <div className="col-lg-6 mb-7">
                                                <label
                                                    htmlFor="Country"
                                                    className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                                                >
                                                    Country
                                                    <input
                                                        type="text"
                                                        className="form-control mt-5"
                                                        placeholder="Country"
                                                        id="Country"
                                                        name="Country"
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </label>
                                            </div>

                                            <div className="col-lg-6 mb-7">
                                                <label
                                                    htmlFor="OrgPhone"
                                                    className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                                                >
                                                    Organization Phone
                                                    <br />
                                                    <input
                                                        type="text"
                                                        className="form-control mt-5"
                                                        placeholder="ISD"
                                                        id="OrgIsd"
                                                        name="OrgIsd"
                                                        style={{ float: "left", width: "25%" }}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    <span style={{ float: "left", width: "5%", textAlign: "center", paddingTop: "1.75rem" }}> - </span>
                                                    <input
                                                        type="text"
                                                        className="form-control mt-5"
                                                        placeholder="Phone Number"
                                                        id="OrgPhone"
                                                        name="OrgPhone"
                                                        style={{ float: "left", width: "70%" }}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </label>
                                            </div>


                                            <div className="col-lg-6 mb-7">
                                                <label
                                                    htmlFor="YourName"
                                                    className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                                                >
                                                    Your Name
                                                    <input
                                                        type="text"
                                                        className="form-control mt-5"
                                                        placeholder="Your Name"
                                                        id="YourName"
                                                        name="YourName"
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </label>
                                            </div>
                                            <div className="col-lg-6 mb-7">
                                                <label
                                                    htmlFor="Designation"
                                                    className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                                                >
                                                    Designation
                                                    <input
                                                        type="text"
                                                        className="form-control mt-5"
                                                        placeholder="Designation"
                                                        id="Designation"
                                                        name="Designation"
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </label>
                                            </div>

                                            <div className="col-lg-6 mb-7">
                                                <label
                                                    htmlFor="Email"
                                                    className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                                                >
                                                    E-mail
                                                    <input
                                                        type="email"
                                                        className="form-control mt-5"
                                                        placeholder="example@gmail.com"
                                                        id="Email"
                                                        name="Email"
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </label>
                                            </div>
                                            <div className="col-lg-6 mb-7">
                                                <label
                                                    htmlFor="YourPhone"
                                                    className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                                                >
                                                    Your Phone
                                                    <br />
                                                    <input
                                                        type="text"
                                                        className="form-control mt-5"
                                                        placeholder="ISD"
                                                        id="YourIsd"
                                                        name="YourIsd"
                                                        onChange={handleChange}
                                                        style={{ float: "left", width: "25%" }}
                                                        required
                                                    />
                                                    <span style={{ float: "left", width: "5%", textAlign: "center", paddingTop: "1.75rem" }}> - </span>
                                                    <input
                                                        type="text"
                                                        className="form-control mt-5"
                                                        placeholder="Phone Number"
                                                        id="YourPhone"
                                                        name="YourPhone"
                                                        onChange={handleChange}
                                                        style={{ float: "left", width: "70%" }}
                                                        required
                                                    />
                                                </label>
                                            </div>

                                            <div className="col-lg-12 mb-7">
                                                <label
                                                    htmlFor="Subject"
                                                    className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                                                    style={{ width: "100%" }}
                                                >
                                                    Subject
                                                    <input
                                                        type="text"
                                                        className="form-control mt-5"
                                                        placeholder="Subject"
                                                        id="Subject"
                                                        name="Subject"
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </label>
                                            </div>
                                            <div className="col-lg-12 mb-7">
                                                <label
                                                    htmlFor="Message"
                                                    className="font-size-4 font-weight-semibold text-black-2 line-height-reset"
                                                    style={{ width: "100%" }}
                                                >
                                                    Message
                                                    <textarea
                                                        id="message"
                                                        placeholder="Brief message on your requirements"
                                                        className="form-control mt-5 h-px-144"
                                                        name="message"
                                                        onChange={handleChange}
                                                        required
                                                    ></textarea>
                                                </label>
                                            </div>
                                            <div className="col-lg-12 pt-4">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary text-uppercase w-100 h-px-48"
                                                    style={{ borderColor: "rgb(245, 153, 48)", backgroundColor: "rgb(245, 153, 48)" }}
                                                >
                                                    Send Now
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="mt-8">
                                        <h3 className="font-size-4">Our Contact Details</h3>
                                        <div className="media mb-2">
                                            <div className="mr-6">
                                                <i className="fas fa-envelope mt-2"></i>
                                            </div>
                                            <p className="font-size-4 mb-0">info@VOCALGLOCAL.org</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageWrapper>
        </>
    );
};
export default Contact;
